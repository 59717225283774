var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home matches-page" },
    [
      _c("PageTitle", [_vm._v("Collegiate Matches")]),
      _c(
        "AppTabs",
        { attrs: { lazy: "" } },
        [
          _c(
            "AppTab",
            { attrs: { title: "Search" } },
            [
              _c("SearchStateController", {
                ref: "searchCtrl",
                attrs: { default: _vm.defaultFilters, s: _vm.s },
                on: { change: _vm.onSearchChange },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var state = ref.state
                      return [
                        _c("CollegiateSearchFilters", {
                          attrs: { disabled: !!_vm.loading, value: state },
                          on: { update: _vm.onSearch }
                        }),
                        _c("hr"),
                        _c(
                          "AppTabs",
                          [
                            _c(
                              "AppTab",
                              { attrs: { title: "Found matches" } },
                              [
                                _c("CollegiateMatchSearchController", {
                                  attrs: {
                                    filter: state,
                                    "per-page": _vm.perPage,
                                    selected: _vm.selectedMatches,
                                    table:
                                      _vm.$options.components
                                        .MatchBookmarkController,
                                    "tracking-context": {
                                      page: "matches",
                                      section: "results"
                                    }
                                  },
                                  on: {
                                    "update:per-page": _vm.updateItemsPerPage,
                                    "update:selected": function($event) {
                                      _vm.selectedMatches = $event
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "AppTab",
                              {
                                attrs: {
                                  title:
                                    "Selected matches (" +
                                    _vm.selectedMatchesCount +
                                    ")"
                                }
                              },
                              [
                                _c(
                                  "MatchesTable",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          items: _vm.selectedMatches,
                                          selected: _vm.selectedMatches,
                                          "tracking-context": {
                                            page: "matches",
                                            section: "selected"
                                          },
                                          bookmarkable: false,
                                          "is-collegiate": true
                                        },
                                        on: {
                                          "update:selected": function($event) {
                                            _vm.selectedMatches = $event
                                          }
                                        }
                                      },
                                      "MatchesTable",
                                      _vm.$attrs,
                                      false
                                    ),
                                    _vm.$listeners
                                  )
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }