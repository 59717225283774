var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ApiListController", {
    attrs: {
      fetch: _vm.fetch,
      filter: _vm.fetchParams,
      name: "Matches",
      "per-page": _vm.perPageValue
    },
    on: {
      "update:perPage": function($event) {
        _vm.perPageValue = $event
      },
      "update:per-page": function($event) {
        _vm.perPageValue = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var items = ref.items
          var total = ref.total
          return [
            total > 0
              ? _c(
                  _vm.tableComponent,
                  _vm._g(
                    _vm._b(
                      {
                        tag: "component",
                        attrs: {
                          items: items,
                          "highlight-agents": _vm.fetchParams.agent_ids,
                          "highlight-agents-option":
                            _vm.fetchParams.agent_option,
                          "no-local-sorting": "",
                          "sort-by": _vm.sortBy,
                          "sort-desc": _vm.sortDesc,
                          bookmarkable: false,
                          "is-collegiate": true
                        },
                        on: { "sort-changed": _vm.onSortChanged }
                      },
                      "component",
                      _vm.$attrs,
                      false
                    ),
                    _vm.$listeners
                  )
                )
              : _c(
                  "div",
                  { staticClass: "text-muted text-center" },
                  [
                    _vm._v(" There are no results matching your filters. "),
                    _c(
                      "router-link",
                      {
                        staticClass: "router-link-active",
                        attrs: { to: { query: { s: _vm.noFiltersSearch } } }
                      },
                      [_vm._v("Try without.")]
                    )
                  ],
                  1
                )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }